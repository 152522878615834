import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button'
import Form from '../Form/Form'
import Text from '../Text/Text'
import Underline from '../Underline/Underline'
import { fees } from '../../util/data'
import * as AppointmentStyles from './Appointments.module.css'

function Fees({ service, fee }) {
    return <li className={AppointmentStyles.fee}><div className={AppointmentStyles.service}>{service}</div><div className={AppointmentStyles.price}>{fee}</div></li>
}

function Appointments(props) {
    return (
        <div className={AppointmentStyles.wrapper}>
            <Text>
                <Underline>
                    <h2>Appointments & Fees</h2>
                </Underline>
                <p>We can typically see our patients within 24 hours of contacting the clinic. No waiting, just quick, easy access to a highly trained primary care specialist who is able to devote the time it takes to understand you, your symptoms and assess you health to provide you with an individualised treatment plan.</p>
                <Button external={true} to="https://eubookings.nookal.com/bookings/location/GGNJC" type="primarySolid">Book online today</Button>
            </Text>
            <div className={AppointmentStyles.container}>
                <div>
                    <h3>Fees</h3>
                    {
                        (props.fees === "osteopathy" || props.fees === "all") && (
                            <>
                                <h4>Osteopathy</h4>
                                <ul>
                                    {
                                        fees.osteopathy.map(service => (
                                            <Fees key={service.id} service={service.service} fee={service.price} />
                                        ))
                                    }
                                </ul>
                            </>
                        )
                    }
                    {
                        (props.fees === "massage" || props.fees === "all") && (
                            <>
                                <h4>Sports & Remedial Massage</h4>
                                <ul>
                                    {
                                        fees.massage.map(service => (
                                            <Fees key={service.id} service={service.service} fee={service.price} />
                                        ))
                                    }
                                </ul>
                            </>
                        )
                    }
                    {
                        (props.fees === "cranial" || props.fees === "all") && (
                            <>
                                <h4>Cranial Osteopathy</h4>
                                <ul>
                                    {
                                        fees.cranial.map(service => (
                                            <Fees key={service.id} service={service.service} fee={service.price} />
                                        ))
                                    }
                                </ul>
                            </>
                        )
                    }
                    {
                        (props.fees === "cranial" || props.fees === "all") && (
                            <>
                                <h4>Cranial Osteopathy: Babies & Children</h4>
                                <ul>
                                    {
                                        fees.infantCranial.map(service => (
                                            <Fees key={service.id} service={service.service} fee={service.price} />
                                        ))
                                    }
                                </ul>
                            </>
                        )
                    }
                    {
                        (props.fees === "feeding" || props.fees === "all") && (
                            <>
                                <h4>Infant Feeding Coaching</h4>
                                <ul>
                                    {
                                        fees.feeding.map(service => (
                                            <Fees key={service.id} service={service.service} fee={service.price} />
                                        ))
                                    }
                                </ul>
                            </>
                        )
                    }
                    <h3>Location</h3>
                    <p>Our team operates from a relaxing and quiet clinic boasting convenient parking and disabled access.</p>
                    <p className={AppointmentStyles.address}>
                        Brixworth Osteopathic Clinic<br />
                        Lamport Manor Farm<br />
                        Coach House 2<br />
                        Old Road<br />
                        Lamport<br />
                        Northants<br />
                        NN6 9HF
                    </p>
                </div>
                <div className={AppointmentStyles.formContainer}>
                <h3>Get in Touch</h3>
                    <p>If you'd like to discuss an appointment or if you have any questions you can contact us using the details below or by filling in the contact form.</p>
                    <a className={AppointmentStyles.contact} href="tel:+441604328260"><FontAwesomeIcon style={{marginRight: 10}} icon={faPhone} />01604 328 260</a>
                    <a className={AppointmentStyles.contact} href="mailto: info@brixworthosteopathy.com"><FontAwesomeIcon style={{marginRight: 10}} icon={faEnvelope} />info@brixworthosteopathy.com</a>
                    <Form />
                </div>
            </div>
        </div>
    )
}

export default Appointments